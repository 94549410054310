.container img {
    width: 200px;
}

@media only screen and (max-width: 768px) {
    .container img {
        width: 150px;
    }
}

@media only screen and (max-width: 480px) {
    .container img {
        width: 100px;
    }
}