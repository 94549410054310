.open-source-container {
  display: flex;
  border: 2px solid #ddd;
  border-radius: 15px;
  padding: 15px;
  margin: 30px;
}

.image-container {
  flex: 1;
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.course-details {
  flex: 2;
  padding-left: 4%;
}

.course-details p {
  margin-top: 10px;
  margin-bottom: 15px;
}

.course-details h2 {
  margin-top: 15px;
}

.details-with-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.details-with-icon p {
  margin: 0;
}

.buttons-container {
  margin-top: 10px;
  display: flex;
  gap: 20px;
}

.button {
  flex: 1;
  margin-right: 5px;
  padding: 10px;
  background-color: black;
  border: 1px solid black;
  border-radius: 4px;
  text-decoration: none;
  color: white;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}

.button:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 480px) {
  .open-source-container {
    flex-direction: column;
    padding: 30px;
  }

  .course-details {
    padding-left: 0;
  }

  .course-details h2 {
    font-size: small;
  }

  .course-details p {
    font-size: x-small;
  }

  .buttons-container a {
    font-size: x-small;
  }

  .buttons-container {
    flex-direction: column;
    gap: 10px;
  }

  .details-with-icon a {
    font-size: x-small;
  }
}

@media only screen and (max-width: 768px) {
  .open-source-container {
    flex-direction: column;
    padding: 30px;
  }

  .course-details {
    padding-left: 0;
  }

  .course-details h2 {
    font-size: large;
  }

  .course-details p {
    font-size: small;
  }

  .buttons-container {
    gap: 10px;
  }

  .buttons-container a {
    font-size: small;
  }

  .details-with-icon a {
    font-size: small;
  }
}

@media only screen and (max-width: 1024px) {
  .open-source-container {
    flex-direction: column;
    padding: 30px;
  }

  .course-details {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 1280px) {
  .open-source-container {
    padding: 20px;
  }

  .course-details h2 {
    font-size: medium;
    margin-top: 0;
  }

  .course-details p {
    margin-top: 4.5px;
    margin-bottom: 0;
    font-size: small;
  }

  .buttons-container {
    gap: 10px;
  }

  .buttons-container a {
    font-size: small;
  }

  .details-with-icon a {
    font-size: small;
  }
}