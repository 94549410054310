/* Styling for all committees' sections */
.committee-desc-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
}

.committee-desc-container-edu {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;

}

.text-section {
  flex: 1;
  text-align: center;
  max-width: 70%;
  padding: 20px;
  margin-right: 90px;
}

.text-section-EDU {
  flex: 1;
  text-align: center;
  max-width: 70%;
  padding: 20px;
  margin-left: 100px;
}

.text-section h1 {
  font-size: 35px;
  margin-bottom: 10px;
  color: #B00707;
  text-shadow: 0 0 10px rgba(176, 7, 7, 0.8);
  animation: flicker_cp 5s ease-in-out infinite;
}

.text-section-EDU h1 {
  font-size: 35px;
  margin-bottom: 10px;
  color: #0089FF;
  text-shadow: 0 0 10px rgba(0, 137, 255, 0.8);
  animation: flicker_edu_glow 5s ease-in-out infinite;
}

.text-section-AI h1 {
  color: #47817F;
  text-shadow: 0 0 10px rgba(71, 129, 127, 0.8);
  animation: flicker_ai_glow 5s ease-in-out infinite;
}

.text-section p {
  font-size: 18px;
}

.text-section-EDU p {
  font-size: 18px;
}

.banner-section {
  flex: 1;
  text-align: center;
  border: 2px solid #ddd;
  max-width: 50%;
  border-radius: 15px;
}

.banner-section img {
  border-radius: 15px;
}

.banner-section img {
  max-width: 100%;
  height: auto;
}

@keyframes flicker_cp {

  0%,
  100% {
    text-shadow: 0 0 10px rgba(176, 7, 7, 0.8);
  }

  25%,
  75% {
    text-shadow: 0 0 0 rgba(176, 7, 7, 0.8);
  }

  50% {
    text-shadow: 0 0 20px rgba(176, 7, 7, 0.8);
  }
}

@keyframes flicker_edu_glow {

  0%,
  100% {
    text-shadow: 0 0 10px rgba(0, 137, 255, 0.8);
  }

  25%,
  75% {
    text-shadow: 0 0 0 rgba(0, 137, 255, 0.8);
  }

  50% {
    text-shadow: 0 0 20px rgba(0, 137, 255, 0.8);
  }
}

@keyframes flicker_ai_glow {

  0%,
  100% {
    text-shadow: 0 0 10px rgba(71, 129, 127, 0.8);
  }

  25%,
  75% {
    text-shadow: 0 0 0 rgba(71, 129, 127, 0.8);
  }

  50% {
    text-shadow: 0 0 20px rgba(71, 129, 127, 0.8);
  }
}

@media screen and (max-width: 530px) {
  .committee-desc-container {
    flex-direction: column-reverse;
    padding: 30px;
  }

  .committee-desc-container-edu {
    flex-direction: column;
    padding: 30px;
  }

  .text-section {
    max-width: 100%;
    margin-right: 0;
  }

  .banner-section {
    max-width: 100%;
  }

  .text-section-EDU {
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .text-section p {
    font-size: 12px !important;
  }

  .text-section-EDU p {
    font-size: 12px !important;
  }

  .text-section-EDU h1 {
    font-size: 23px ;
  }

  .text-section h1 {
    font-size: 23px;
  }
}

@media screen and (max-width: 1024px) {

  .committee-desc-container {
    flex-direction: column-reverse;
    padding: 30px;
  }

  .committee-desc-container-edu {
    flex-direction: column;
    padding: 30px;
  }

  .text-section {
    max-width: 100%;
    margin-right: 0;
  }

  .banner-section {
    max-width: 100%;
  }

  .text-section-EDU {
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .text-section p {
    font-size: 21px;
  }

  .text-section-EDU p {
    font-size: 21px;
  }
}