.fun-message {
  position: fixed;
  left: 0;
  right: 0;
  background-color: #840032;
  color: #fff;
  padding: 10px;
  text-align: center;
}

.fun-message p {
  margin: 0;
}

.fun-message button {
  background-color: white;
  color: #840032;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  margin-top: 5px;
  cursor: pointer;
}