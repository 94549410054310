.welcome-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 75px;
}

.large-font {
    font-size: 35px;
    padding-bottom: 10px;
}

.medium-font {
    font-size: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.closing-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 20px 0;
}



@media only screen and (max-width: 480px) {
    .medium-font {
        font-size: 15px;
        padding: 10px;
    }

    .large-font {
        font-size: 20px;
    }
}