.committees-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 25px;
}

.top-section {
  margin-top: 35px;
  margin-bottom: 25px;
  text-align: center;
}

.top-section h2 {
  margin-bottom: 10px;
}

.top-section p {
  margin-top: 10px;
  font-size: 19px;
  padding: 10px;
}

.bottom-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.committee {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.committee img {
  max-width: 50%;
  height: auto;
}

.cp_glow {
  margin-bottom: 5px;
  color: #B00707;
  text-shadow: 0 0 10px rgba(176, 7, 7, 0.8);
  animation: flicker_cp_glow 5s ease-in-out infinite;
}

.ai_glow {
  margin-bottom: 5px;
  color: #47817F;
  text-shadow: 0 0 10px rgba(71, 129, 127, 0.8);
  animation: flicker_ai_glow 5s ease-in-out infinite;
}

.edu_glow {
  margin-bottom: 5px;
  color: #0089FF;
  text-shadow: 0 0 10px rgba(0, 137, 255, 0.8);
  animation: flicker_edu_glow 5s ease-in-out infinite;
}

.committee p {
  margin-top: 5px;
}


@keyframes flicker_cp_glow {

  0%,
  100% {
    text-shadow: 0 0 10px rgba(176, 7, 7, 0.8);
  }

  25%,
  75% {
    text-shadow: 0 0 0 rgba(176, 7, 7, 0.8);
    /* Use a shadow with no blur to simulate turning off */
  }

  50% {
    text-shadow: 0 0 20px rgba(176, 7, 7, 0.8);
  }
}

@keyframes flicker_ai_glow {

  0%,
  100% {
    text-shadow: 0 0 10px rgba(71, 129, 127, 0.8);
  }

  25%,
  75% {
    text-shadow: 0 0 0 rgba(71, 129, 127, 0.8);
  }

  50% {
    text-shadow: 0 0 20px rgba(71, 129, 127, 0.8);
  }
}

@keyframes flicker_edu_glow {

  0%,
  100% {
    text-shadow: 0 0 10px rgba(0, 137, 255, 0.8);
  }

  25%,
  75% {
    text-shadow: 0 0 0 rgba(0, 137, 255, 0.8);
  }

  50% {
    text-shadow: 0 0 20px rgba(0, 137, 255, 0.8);
  }
}

@media screen and (max-width: 530px) {
  .committees-container p {
    font-size: 14px;
  }

  .committee img {
    max-width: 80px;
  }

  .committee p {
    font-size: 10px;
    max-width: 90%;
    justify-content: center;
  }

  .committee h3 {
    font-size: 10px;
  }
}

@media screen and (max-width: 280) {
  .committee h3 {
    font-size: xx-small;
  }

  .committee p {
    font-size: xx-small;
  }

  .committee img {
    max-width: 30px;
  }
}


@media screen and (min-width: 1024px) {
  .bottom-section {
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: 20px;
  }
}