.about-us-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding-top: 2vh;
  text-align: center;
}

.about-us-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.goal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 7vh;
  text-align: center;
}

.about-us-left {
  text-align: center;
  margin-right: 5%;
  padding: 10px;
  max-width: 60%;
  margin-left: 5%;
}

.about-us-left div {
  margin-bottom: 20px;
}

.about-us-right {
  max-width: 30%;
  margin-top: 10px;
}

.about-us-left h2 {
  margin-bottom: 10px;
}


@media only screen and (max-width: 480px) {
  .about-us-left h2 {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .about-us-left p {
    font-size: 10px;
  }

  .about-us-left {
    margin-right: 0;
    padding: 10px;
    max-width: 60%;
    margin-left: 0;
  }

}