.footer-container {
  background-color: #242424;
  padding: 4rem 2rem 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center-section li a {
  text-decoration: underline;
  color: white;
}


.center-section li a:hover {
  color: blueviolet;
}

.aub {
  width: 200px ;
  height: auto;
}

.left-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:10px;
}

.msfea {
  width:60px;
  height: auto;
}

.center-section {
  text-align: center;
  color: white;
}

.center-section h4 {
  margin-bottom: 1rem;
}

.center-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.center-section li {
  margin-bottom: 0.5rem;
}

.right-section {
  text-align: right;
  color: white;
}

.right-section p {
  margin-bottom: 1rem;
  text-align: center;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon-link {
  text-decoration: none;
  margin-right: 10px;
  margin-left: 10px;
}

.social-icon-link i {
  font-size: 2rem;
  color: white;
  transition: color 0.3s ease;
}

.social-icon-link:hover i {
  color: blueviolet;
}

@media screen and (min-width: 960px) {

  .social-icon-link i {
    font-size: 4rem;
  }

  .footer-container {
    padding: 4rem 16rem 2rem 10rem;
  }

  .aub{
    width: 250px;
  }
  .msfea {
    width: 80px;
  }

  .center-section h4 {
    font-size: larger;
  }
  .logos {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .social-icon-link {
    margin-right: 5px;
  }

  .aub {
    width: 120px;
  }

  .msfea {
    width: 40px;
  }


  .center-section h4 {
    margin-bottom: 0.5rem;
    font-size: small;
  }

  .center-section li {
    font-size: x-small;
    margin-bottom: 0.2rem;
  }

  .right-section p {
    font-size: x-small;
  }
  .logos {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .logos {
    display: flex;
    align-items: center;
  }
}